import React from "react";
import { fadeInLeft } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import classes from "../styles/Button.module.css";

const styles = StyleSheet.create({
  fadeInRight: {
    animationName: fadeInLeft,
    animationDuration: "1s",
  },
});
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);

const Button = ({ text, handleClick, id, active }) => {
  return (
    <button
      tabIndex={isMobile ? -1 : 0}
      onClick={handleClick}
      className={`${css(styles.fadeInRight)} ${classes.btn} ${
        active ? classes.active : ""
      }`}
      id={id}
    >
      {text}
    </button>
  );
};

export default Button;
