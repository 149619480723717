import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Header from "../components/Header";
import classes from "../styles/Design.module.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
const Design = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header text="Design" />
      <br></br>
      <div className={classes.mainContainer}>
        <div
          onClick={() => navigate("/design/add")}
          className={classes.addItem}
        >
          <AddCircleOutlineIcon />
        </div>
      </div>
    </>
  );
};

export default Design;
