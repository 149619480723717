import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCe-c-SyHMdLAOGSoTN3W3rdW1bM6FYO8Q",
  authDomain: "tygersden-7a964.firebaseapp.com",
  projectId: "tygersden-7a964",
  storageBucket: "tygersden-7a964.appspot.com",
  messagingSenderId: "895214692147",
  appId: "1:895214692147:web:a724f692c8c8de8ac9c046",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;
