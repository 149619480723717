import { useState } from "react";
import classes from "../styles/PostCard.module.css";
import ReactCardFlip from "react-card-flip";
import { franc } from "franc-min";
import axios from "axios";
import { Delete, IosShare, Send } from "@mui/icons-material";

const PostCard = ({
  Headline,
  Image,
  Vertical,
  Link,
  RunRate,
  Id,
  SuperRunRate,
  Time,
  Chk,
  setForwardMode,
  setDeletePrompt,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  function getLanguageClass(text) {
    const detectedLanguage = franc(text);

    // franc returns 'eng' for English and 'ben' for Bangla
    if (detectedLanguage === "eng") {
      return "english";
    } else if (detectedLanguage === "ben") {
      return "bangla";
    } else {
      return "other";
    }
  }

  return (
    <>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <div
          onClick={() => setIsFlipped(true)}
          className={`${classes.cardContainer} ${
            SuperRunRate > 1 && SuperRunRate < 2
              ? classes.yellow
              : SuperRunRate > 2
              ? classes.red
              : ``
          }`}
        >
          <div>
            <label
              className={
                getLanguageClass(Headline) == "bangla" ? `${classes.bn}` : ``
              }
            >
              {`${Headline.substring(0, 120)} ${
                Headline.length > 120 ? `...` : ``
              }`}
            </label>

            <a href={Link} target="_blank">
              <label className={classes.src}>
                {Vertical && Vertical.length > 15
                  ? `${Vertical.substring(0, 15)}...`
                  : Vertical}
              </label>
            </a>
            <label className={classes.runRate}>
              {SuperRunRate != 0 ? parseFloat(SuperRunRate).toFixed(2) : 0}
            </label>
            {Image && (
              <img
                className={
                  (Headline == undefined || Headline == "") && classes.fullH
                }
                src={Image}
              />
            )}
          </div>
        </div>
        <div
          onClick={() => setIsFlipped(false)}
          className={`${classes.cardContainer} ${
            SuperRunRate > 1 && SuperRunRate < 2
              ? classes.yellow
              : SuperRunRate > 2
              ? classes.red
              : ``
          }`}
        >
          <div className={classes.back}>
            <div>
              <span>sRR</span>
              <label>
                {SuperRunRate != 0 ? parseFloat(SuperRunRate).toFixed(2) : 0}
              </label>
            </div>
            <div>
              <span>cRR</span>
              <label>
                {RunRate != 0 && !isNaN(RunRate) && isFinite(RunRate)
                  ? parseFloat(RunRate).toFixed(2)
                  : 0}
              </label>
            </div>
            <div>
              <span>Posted</span>
              <label>{Time}</label>
            </div>
            <div className={classes.btnHolder}>
              <div
                onClick={() => {
                  setDeletePrompt({ link: Link, id: Id });
                  console.log(Id);
                }}
                className={classes.dltBtn}
              >
                <Delete />
              </div>
              <div
                onClick={() => {
                  setForwardMode({
                    link: Link,
                    srr: `${
                      SuperRunRate != 0
                        ? parseFloat(SuperRunRate).toFixed(2)
                        : 0
                    }`,
                  });
                  console.log(Id);
                }}
                className={classes.dltBtn}
              >
                <IosShare />
              </div>
            </div>

            {/* <button onClick={() => sendTelegramMessage(Link)}>Forward</button> */}
          </div>
        </div>
      </ReactCardFlip>
    </>
  );
};

export default PostCard;
