import classes from "../styles/TextArea.module.css";

const TextArea = ({ placeholder, handleChange }) => {
  return (
    <>
      <textarea
        className={classes.inputBox}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </>
  );
};

export default TextArea;
