import classes from "../styles/Input.module.css";

const Input = ({ placeholder, handleChange }) => {
  return (
    <>
      <input
        className={classes.inputBox}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </>
  );
};

export default Input;
