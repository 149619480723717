import { useState } from "react";
import classes from "../styles/SourceCard.module.css";
import ReactCardFlip from "react-card-flip";
import { franc } from "franc-min";
import axios from "axios";

const SourceCard = ({ Source, aRR, count, updated, numS }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [intl] = useState([
    "The Verge",
    "Reuters",
    "CNN",
    "BBC News",
    "NME",
    "NY Post",
    "Guardian",
    "Washington Post",
    "Fox News",
    "Daily Mail",
  ]);
  const [local] = useState([
    "Dhaka Tribune",
    "The Business Standard",
    "Prothom Alo",
    "Daily Star Bangla",
    "The Financial Express",
  ]);

  function timeAgoFirebase(timestamp) {
    const date = timestamp.toDate(); // convert the Firebase Timestamp to a JavaScript Date object
    const now = new Date();
    let seconds = Math.floor((now - date) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    if (hours > 0) {
      return hours + "hr";
    } else if (minutes > 0) {
      return minutes + "m";
    } else {
      return seconds + "s";
    }
  }

  return (
    <>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <div
          onClick={() => setIsFlipped(true)}
          className={`${classes.cardContainer} ${
            local.includes(Source) && `${classes.yellow}`
          }`}
        >
          <div>
            <label>{Source}</label>
          </div>
          <br></br>
          <div>
            <label className={classes.arrNum}>
              {aRR != 0 && !isNaN(aRR) ? parseFloat(aRR).toFixed(2) : 0}
            </label>
            <div
              style={{ fontSize: "1rem", display: "inline-block" }}
            >{`(${numS})`}</div>
          </div>
        </div>
        <div
          onClick={() => setIsFlipped(false)}
          className={`${classes.cardContainer} ${
            local.includes(Source) && `${classes.yellow}`
          }`}
        >
          <div className={classes.back}>
            <div>
              <label>
                {count}
                <span> Posts Today</span>
              </label>
            </div>
            <div>
              <label>
                <span>Updated </span>
                {updated ? timeAgoFirebase(updated) : ``}
              </label>
            </div>
          </div>
        </div>
      </ReactCardFlip>
    </>
  );
};

export default SourceCard;
