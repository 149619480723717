import { useNavigate } from "react-router-dom";
import classes from "../styles/Header.module.css";

const Header = ({ text, hideNav }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.headerContainer}>
      <p className={classes.header}>{text}</p>
      {!hideNav && (
        <div className={classes.nav}>
          <p onClick={() => navigate("/")}>Home</p>
          <p>Tygers</p>
          <p onClick={() => navigate("/sources")}>Sources</p>
        </div>
      )}
    </div>
  );
};

export default Header;
