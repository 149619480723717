import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Nav";
import Curation from "./pages/Curation";
import Sources from "./pages/Sources";
import Design from "./pages/Design";
import DesignCreation from "./pages/DesignCreation";
import Login from "./pages/Login";

function App() {
  return (
    <div className="App">
      <div className="mainContainer">
        <Navbar />
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/" element={<Curation />} />
          <Route exact path="/design" element={<Design />} />
          <Route exact path="/design/add" element={<DesignCreation />} />
          <Route exact path="/sources" element={<Sources />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
