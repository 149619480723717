import { useState, useRef, useEffect } from "react";
import domtoimage from "dom-to-image";
import Header from "../components/Header";
import classes from "../styles/DesignCreation.module.css";
import Button from "../components/Button";
import Input from "../components/Input";
import DropdownInput from "../components/DropdownInput";
import TextArea from "../components/TextArea";
import axios from "axios";
import logoNT from "../assets/nt.png";

const DesignCreation = () => {
  const [outputImg, setOutputImg] = useState();
  const [category, setCategory] = useState();
  const [source, setSource] = useState();
  const [newsImg, setNewsImg] = useState();
  const [tone, setTone] = useState("Blue");
  const [multiplier, setMultiplier] = useState(0);
  const [newsCutoutImg, setNewsCutoutImg] = useState();
  const [pictureCredit, setPictureCredit] = useState();
  const [headline, setHeadline] = useState();
  const [selectedStyle, setSelectedStyle] = useState();
  const [selectedWords, setSelectedWords] = useState([]);
  const [vertical, setVertical] = useState("NT");
  const [imgStyle, setImgStyle] = useState(["No Style", "Style1", "Style2"]);

  const imageRef = useRef();
  const backgroundImg = useRef();
  const cutoutImg = useRef();
  const headlineWords = (headline || "").split(/[\s\n]+/).filter(Boolean);

  // Updated: Toggle word's selected status
  const toggleSelectedWord = (word) => {
    if (selectedWords.includes(word)) {
      setSelectedWords(
        selectedWords.filter((selectedWord) => selectedWord !== word)
      );
    } else {
      setSelectedWords([...selectedWords, word]);
    }
  };

  const getDate = () => {
    let currentDate = new Date();

    let options = { day: "numeric", month: "long", year: "numeric" };

    const formattedDate = currentDate.toLocaleDateString("en-GB", options);

    return formattedDate;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewsImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCutoutChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Convert the file to a data URL using FileReader
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const dataUrl = reader.result;

        // Prepare the form data to send to the remove.bg API
        const formData = new FormData();
        formData.append("size", "auto");
        formData.append("image_file", file, file.name);

        try {
          // Call the remove.bg API to remove the background
          console.log("sending");
          const response = await axios({
            method: "post",
            url: "https://api.remove.bg/v1.0/removebg",
            data: formData,
            responseType: "arraybuffer",
            headers: {
              "Content-Type": `multipart/form-data; boundary=${formData._boundary}`, // Manually set the content-type header
              "X-Api-Key": "GL6i7szujfry1Zz4p6qkz1Er",
            },
          });
          console.log("sent");

          // Check if the status is 200, else throw an error
          if (response.status !== 200) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }

          // Convert the response data into a Blob
          const responseBlob = new Blob([response.data], { type: "image/png" });

          // Create a URL from the Blob and set it as the cutout image
          const cutoutImageUrl = URL.createObjectURL(responseBlob);
          setNewsCutoutImg(cutoutImageUrl);
        } catch (error) {
          console.error("Request failed:", error);
        }
      };
    }
  };

  useEffect(() => {
    domtoimage
      .toPng(imageRef.current, {
        width: 2048,
        height: 2048,
      })
      .then((dataUrl) => {
        setOutputImg(dataUrl);
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
      });
  }, [
    headline,
    selectedWords,
    source,
    category,
    pictureCredit,
    newsImg,
    newsCutoutImg,
    multiplier,
    selectedStyle,
    tone,
  ]);

  return (
    <>
      <Header text="New Design" />
      <br></br>
      <br></br>
      <div className={classes.designCreationContainer}>
        <div className={classes.imgContainer}>
          <img
            className={!outputImg ? classes.default : ``}
            src={
              outputImg ||
              `https://designshack.net/wp-content/uploads/placeholder-image.png`
            }
          ></img>
        </div>
        <br></br>
        <div className={classes.rightContainer}>
          <div>
            <Button
              handleClick={() => setVertical("NT")}
              text="NT"
              active={vertical == "NT"}
            />
            {/* <Button
              handleClick={() => setVertical("CG")}
              text="CG"
              active={vertical == "CG"}
            /> */}
          </div>

          <br></br>
          <div>
            <Button
              handleClick={() => setTone("Red")}
              text="Red"
              active={tone == "Red"}
            />
            <Button
              handleClick={() => setTone("Blue")}
              text="Blue"
              active={tone == "Blue"}
            />
          </div>
          <div className={classes.inputs}>
            <TextArea
              placeholder="Headline"
              handleChange={(e) => setHeadline(e.target.value)}
            />
            <div>
              {headlineWords.map((word, index) => (
                <Button
                  key={index}
                  handleClick={() => toggleSelectedWord(word)}
                  text={word}
                  active={selectedWords.includes(word)}
                />
              ))}
            </div>
            <Input
              placeholder="Source"
              handleChange={(e) => setSource(e.target.value)}
            />
            {vertical == "NT" && (
              <Input
                placeholder="Picture"
                handleChange={(e) => setPictureCredit(e.target.value)}
              />
            )}
            <DropdownInput
              handleChange={(val) => setCategory(val)}
              placeholder="Category"
              options={[
                "National",
                "International",
                "Economy",
                "Education",
                "Crime",
                "Technology",
                "Business",
                "Sports",
              ]}
            />
          </div>
          <div>
            <div>
              <Button
                text={
                  newsImg || newsImg == "" ? "Change Image" : "Upload Image"
                }
                handleClick={() => {
                  backgroundImg.current.click();
                }}
              />
            </div>
            <div>
              <Button
                text={
                  cutoutImg || cutoutImg == "" ? "Change Cutout" : "Add Cutout"
                }
                handleClick={() => {
                  cutoutImg.current.click();
                }}
              />
            </div>
          </div>
          <div>
            <input
              type="range"
              defaultValue="0"
              min="1"
              max="3"
              step="0.1"
              onChange={(e) => setMultiplier(e.target.value)}
            ></input>
          </div>
          {newsCutoutImg &&
            newsCutoutImg != "" &&
            imgStyle.map((i) => {
              return (
                <Button text={i} handleClick={() => setSelectedStyle(i)} />
              );
            })}
          <input
            onChange={(e) => handleFileChange(e)}
            ref={backgroundImg}
            type="file"
            style={{ display: "none" }}
          ></input>
          <input
            onChange={(e) => handleCutoutChange(e)}
            ref={cutoutImg}
            type="file"
            style={{ display: "none" }}
          ></input>
        </div>
      </div>
      <div className={classes.designContainerHolder}>
        <div className={classes.mainDesignContainer} ref={imageRef}>
          {newsImg && (
            <img
              src={newsImg ? newsImg : ``}
              className={`${classes.newsImage} ${
                selectedStyle == "Style1"
                  ? classes.greyScale
                  : selectedStyle == "Style2"
                  ? classes.highRed
                  : ``
              }`}
            ></img>
          )}
          {selectedStyle == "Style2" && (
            <div className={classes.style2Div}></div>
          )}
          {newsCutoutImg && (
            <img
              src={newsCutoutImg ? newsCutoutImg : ``}
              className={classes.newsCutoutImage}
              style={
                multiplier
                  ? {
                      transform: `scale(${multiplier}) translate(0, ${
                        10 * multiplier
                      }%)`,
                    }
                  : {}
              }
            ></img>
          )}
          <img className={classes.logoNT} src={logoNT}></img>
          <div className={classes.topBar}></div>
          <div className={classes.headline}>
            {(headline || "").split("\n").map((line, index) => {
              console.log(line.split(" ")[line.split(" ").length - 1]);
              return (
                <p key={index}>
                  {line.split(" ").map((word, indexWord) =>
                    selectedWords.includes(word) ? (
                      <span
                        className={`${tone == "Red" ? classes.red : ``} ${
                          line.split(" ")[line.split(" ").length - 1] == word
                            ? `${classes.spaceAfter}`
                            : ``
                        }`}
                        key={indexWord}
                      >
                        {word + " "}
                      </span>
                    ) : (
                      word + " "
                    )
                  )}
                </p>
              );
            })}
          </div>
          <div className={classes.src}>
            {category?.label} {source ? `| Source: ${source}` : ``}{" "}
            {`| ${getDate()}`}{" "}
            {pictureCredit ? `| Picture: ${pictureCredit}` : ``}
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignCreation;
