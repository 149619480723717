import { useEffect } from "react";
import classes from "../styles/Login.module.css";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  collection,
  doc,
  getDocs,
  increment,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import logo from "../assets/SVG/Asset 1.svg";
import Header from "../components/Header";

const Login = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState();

  const login = async () => {
    console.log(username);
    if (username == "" || password == "") {
      setErr("Invalid Input");
      return;
    }
    const docRef = await getDocs(
      query(collection(db, "Users"), where("username", "==", username))
    );

    if (docRef.empty) {
      setErr("User not Found");
    }
    if (!docRef.empty) {
      if (username == "rumman") {
        await updateDoc(doc(db, "Usages", "rumman"), {
          loginCount: increment(1),
        });
      }

      docRef.forEach((d) => {
        if (d.data().password != password) {
          setErr("Wrong Password");
        } else {
          const date = new Date();
          const expires = new Date();

          // Set the cookie to expire in 7 days
          expires.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

          cookies.set("Login", username, {
            path: "/",
            sameSite: "none",
            secure: true,
            expires: expires,
          });

          if (username === "ibnul") {
            cookies.set("isDenSession", true, {
              path: "/",
              sameSite: "none",
              secure: true,
              expires: expires,
            });
          }

          navigate("/");
        }
      });
    }
  };

  useEffect(() => {
    const cook = cookies.get("Login");
    if (cook) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div style={{ position: "absolute" }}>
        <Header text="Greetings!" hideNav={true} />
      </div>
      <div className={classes.login}>
        <img src={logo}></img>
        <br></br>
        <br></br>
        <br></br>
        <input
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          placeholder="TygerID"
        ></input>
        <input
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Password"
          type="password"
        ></input>
        <br></br>
        {err && (
          <>
            <label>{err}</label>
            <br></br>
          </>
        )}
        <button
          onClick={() => {
            login();
          }}
        >
          Lets Go!
        </button>
      </div>
    </>
  );
};

export default Login;
