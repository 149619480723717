import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import classes from "../styles/DropdownInput.module.css";

const DropdownInput = ({ placeholder, handleChange, options }) => {
  return (
    <div className={classes.container}>
      <Dropdown
        options={options}
        controlClassName={classes.dropDown}
        menuClassName={classes.menu}
        onChange={handleChange}
        // value={defaultOption}
        placeholder={placeholder}
      />
      ;
    </div>
  );
};

export default DropdownInput;
