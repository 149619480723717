import classes from "../styles/OnlineIndicator.module.css";

const OnlineIndicator = () => {
  return (
    <>
      <div className={classes.onlineIndicator}>
        <span className={classes.blink}></span>
      </div>
    </>
  );
};

export default OnlineIndicator;
