import classes from "../styles/Curation.module.css";
import {
  arrayUnion,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import PostCard from "../components/PostCard";
import OnlineIndicator from "../components/OnlineIndicator";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import moment from "moment-timezone";
import { Timestamp } from "firebase/firestore";
import Navbar from "../components/Nav";
import Button from "../components/Button";
import Header from "../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Curation = () => {
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [forwardMode, setForwardMode] = useState(false);
  const [posts, setPosts] = useState([]);
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [trendingPostsInt, setTrendingPostsInt] = useState([]);
  const navigate = useNavigate();
  const [lastActive, setLastActive] = useState(``);
  const [toggleActivity, setToggleActivity] = useState(false);
  const [lastActiveBOT1, setLastActiveBOT1] = useState(``);
  const [status, setStatus] = useState(``);
  const [lastDoc, setLastDoc] = useState(null);
  const [avgRates, setAvgRates] = useState();
  const [unApplied, setUnapplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sourcesToggled, setSourcesToggled] = useState(false);
  const [filterToggled, setFilterToggled] = useState(false);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("Recent");
  const [over, setOver] = useState(false);
  const cookies = new Cookies();
  const [sources] = useState([
    "Dhaka Tribune",
    "The Business Standard",
    "Prothom Alo",
    "Daily Star Bangla",
    "The Financial Express",
    "The Verge",
    "Reuters",
    "CNN",
    "BBC News",
    "NME",
    "NY Post",
    "Guardian",
    "Washington Post",
    "Fox News",
    "Daily Mail",
    "NDTV",
    "Tech Crunch",
    "Fabrizio Romano",
    "Daily Mirror",
    "Kaler Kantho",
    "Indian Express",
    "Economic Times",
    "New Age",
    "Variety",
    "Insider",
    "Business Insider",
    "WIONews",
    "Ign",
    "The Independent",
    "Jamuna Television",
    "Forbes",
    "Bloombergbusiness",
    "RisingBD",
  ]);
  const [filters] = useState(["Recent", "sRR"]);

  async function checkStatus(firebaseTimestamp) {
    const firebaseTimeInMinutes = firebaseTimestamp.seconds / 60;
    const currentTimeInMinutes = Date.now() / 1000 / 60;
    const diffInMinutes = currentTimeInMinutes - firebaseTimeInMinutes;
    if (diffInMinutes > 5) {
      return "offline";
    } else {
      return "online";
    }
  }

  const fetchTrendingPosts = async () => {
    let now = moment().tz("Asia/Dhaka").startOf("day").toDate();
    const deletedPosts = (
      await getDoc(doc(db, "System", "DeletedPosts"))
    ).data().deleted;
    let q = query(
      collection(db, "Data"),
      where("deleted", "==", false),
      where("date", "==", now),
      where("type", "==", "local"),
      orderBy("srr", "desc"),
      orderBy("time", "desc"),
      limit(10)
    );
    let q2 = query(
      collection(db, "Data"),
      where("deleted", "==", false),
      where("date", "==", now),
      where("type", "==", "intl"),
      orderBy("srr", "desc"),
      orderBy("time", "desc"),
      limit(10)
    );

    const posts = [];
    const postsInt = [];
    const querySnapshot = await getDocs(q);
    const querySnapshot2 = await getDocs(q2);
    querySnapshot.forEach((d) => {
      if (
        d.data().vertical != "The Front Page" &&
        d.data().vertical != "Cablgram" &&
        d.data().vertical != "Nutshell Today"
      ) {
        posts.push({ ...d.data(), id: d.id });
      }
    });
    querySnapshot2.forEach((d2) => {
      if (
        d2.data().vertical != "The Front Page" &&
        d2.data().vertical != "Cablgram" &&
        d2.data().vertical != "Nutshell Today"
      ) {
        postsInt.push({ ...d2.data(), id: d2.id });
      }
    });
    setTrendingPosts(posts);
    setTrendingPostsInt(postsInt);
  };

  const fetchPosts = async () => {
    let now = moment().tz("Asia/Dhaka").startOf("day").toDate();
    setLoading(true);
    console.log(now);

    let q;

    if (selectedSources.length == 0) {
      q = query(
        collection(db, "Data"),
        where("deleted", "==", false),
        where("date", "==", now),
        orderBy(selectedFilter == "sRR" ? "srr" : "time", "desc"),
        limit(20)
      );
    } else {
      q = query(
        collection(db, "Data"),
        where("deleted", "==", false),
        where("date", "==", now),
        orderBy(selectedFilter == "sRR" ? "srr" : "time", "desc"),
        where("vertical", "in", selectedSources),
        limit(20)
      );
    }

    if (lastDoc) {
      if (selectedSources.length == 0) {
        q = query(
          collection(db, "Data"),
          where("deleted", "==", false),
          where("date", "==", now),
          orderBy(selectedFilter == "sRR" ? "srr" : "time", "desc"),
          startAfter(lastDoc),
          limit(10)
        );
      } else {
        q = query(
          collection(db, "Data"),
          where("deleted", "==", false),
          where("date", "==", now),
          orderBy(selectedFilter == "sRR" ? "srr" : "time", "desc"),
          where("vertical", "in", selectedSources),
          startAfter(lastDoc),
          limit(10)
        );
      }
    }

    const querySnapshot = await getDocs(q);
    if (lastDoc && querySnapshot.docs.length < 10) {
      setOver(true);
    } else if (!lastDoc && querySnapshot.docs.length < 20) {
      setOver(true);
    }
    const posts = [];
    querySnapshot.forEach((d) => {
      if (
        d.data().vertical != "The Front Page" &&
        d.data().vertical != "Cablgram" &&
        d.data().vertical != "Nutshell Today"
      ) {
        posts.push({ ...d.data(), id: d.id });
        console.log(d.data());
      }
      // console.log(d.data());
    });

    setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setPosts((oldPosts) => [...oldPosts, ...posts]);
    setLoading(false);
  };

  const fetchBotActivity = async () => {
    const docc = await getDoc(doc(db, "System", `lastActive`));
    setLastActive(docc.data().time.toDate().toLocaleString());
    setLastActiveBOT1(docc.data().timeBOT1.toDate().toLocaleString());
    const time = await checkStatus(docc.data().time);
    const timeBOT1 = await checkStatus(docc.data().timeBOT1);
    setStatus({ time, timeBOT1 });
  };

  const calculateAvg = async () => {
    const names = [
      "Kaler Kantho",

      "New Age",

      "Jamuna Television",

      "RisingBD",
      "The Front Page",
      "Cablgram",
      "Nutshell Today",
      "Dhaka Tribune",
      "The Business Standard",
      "Prothom Alo",
      "Daily Star Bangla",
      "The Financial Express",
    ];

    const namesInt = [
      "Fabrizio Romano",
      "Daily Mirror",
      "Indian Express",
      "Economic Times",
      "Variety",
      "Insider",
      "Business Insider",
      "WIONews",
      "Ign",
      "The Independent",
      "Forbes",
      "Bloombergbusiness",
      "The Verge",
      "Reuters",
      "CNN",
      "BBC News",
      "Tech Crunch",
      "NY Post",
      "Guardian",
      "Washington Post",
      "Daily Mail",
      "NDTV",
    ];

    for (let name of names) {
      await calculateVertical(name);
    }

    for (let name of namesInt) {
      await calculateVerticalInt(name);
    }

    // let sevenDaysAgo = new Date(moment().tz("Asia/Dhaka").subtract(30, "days"));
    // console.log(sevenDaysAgo.getTime());
    // const noOfPosts = await getCountFromServer(
    //   query(collection(db, "Data"), where("lastUpdated", ">=", sevenDaysAgo))
    // );
    // console.log(noOfPosts.data().count);
    // const botApi =
    //   "https://api.telegram.org/bot5994217325:AAFuI589JYY3HY1iqVqoXeRpahzq7wU85Hw/sendMessage";
    // try {
    //   const response = await axios.post(botApi, {
    //     chat_id: `-975940438`,
    //     text: `<b>${noOfPosts.data().count} posts</b> scraped in last 30 days`,
    //     parse_mode: "HTML",
    //   });
    //   console.log(response.data);
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const fetchRunrates = async () => {
    const docRef = await getDoc(doc(db, "System", "RunrateData"));
    setAvgRates(docRef.data());
  };

  function timeAgo(dateString) {
    const date = moment(dateString, "M/D/YYYY h:mm:ss A");
    if (!date.isValid()) {
      console.error("Invalid date string: ", dateString);
      return;
    }

    const now = moment();
    const diff = moment.duration(now.diff(date));

    const hours = diff.hours();
    const minutes = diff.minutes();
    const seconds = diff.seconds();

    console.log(hours);
    console.log(minutes);
    console.log(seconds);

    if (hours > 0) {
      return hours + "hr";
    } else if (minutes > 0) {
      return minutes + "m";
    } else {
      return seconds + "s";
    }
  }

  const calculateVertical = async (vertical) => {
    let total = 0;
    let totalRR = 0;
    let avg = 0;
    const nums = [];
    const docRef = await getDocs(
      query(
        collection(db, "Data"),
        where("vertical", "==", vertical),
        limit(500)
      )
    );
    docRef.forEach((d) => {
      if (
        !isNaN(d.data().runrate) &&
        isFinite(d.data().runrate) &&
        d.data().runrate != 0
      ) {
        total++;
        console.log(total);
        totalRR += d.data().runrate;
        nums.push(d.data().runrate);
        console.log(totalRR);
      }
    });
    avg = totalRR / total;
    const obj = {};
    const obj2 = {};
    obj[vertical] = avg;
    obj2[vertical] = nums;
    await updateDoc(doc(db, "System", `RunrateData`), {
      ...obj,
    });
    await updateDoc(doc(db, "System", `TotalData`), {
      ...obj2,
    });
  };
  const calculateVerticalInt = async (vertical) => {
    let total = 0;
    let totalRR = 0;
    let avg = 0;
    const nums = [];
    const docRef = await getDocs(
      query(
        collection(db, "Data"),
        where("vertical", "==", vertical),
        limit(500)
      )
    );
    docRef.forEach((d) => {
      if (
        !isNaN(d.data().runrate) &&
        isFinite(d.data().runrate) &&
        d.data().runrate != 0
      ) {
        total++;
        console.log(total);
        totalRR += d.data().runrate;
        nums.push(d.data().runrate);
        console.log(totalRR);
      }
    });
    avg = totalRR / total;
    avg = avg * 1.2;
    const obj = {};
    const obj2 = {};
    obj[vertical] = avg;
    obj2[vertical] = nums;
    await updateDoc(doc(db, "System", `RunrateData`), {
      ...obj,
    });
    await updateDoc(doc(db, "System", `TotalData`), {
      ...obj2,
    });
  };

  function timeAgoFirebase(timestamp) {
    const date = timestamp.toDate(); // convert the Firebase Timestamp to a JavaScript Date object
    const now = new Date();
    let seconds = Math.floor((now - date) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    if (hours > 0) {
      return hours + "hr";
    } else if (minutes > 0) {
      return minutes + "m";
    } else {
      return seconds + "s";
    }
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1249 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1249, min: 1073 },
      items: 5,
    },
    smallerTablet: {
      breakpoint: { max: 1073, min: 733 },
      items: 4,
    },
    smallerSmallerTablet: {
      breakpoint: { max: 733, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 371 },
      items: 2,
    },
    smallermobile: {
      breakpoint: { max: 371, min: 0 },
      items: 1,
    },
  };

  function toFirebaseTimestamp(dateString) {
    if (typeof dateString !== "string") {
      throw new Error(`Expected a string but got ${typeof dateString}`);
    }

    // Parse the date string with moment.js
    const date = moment(dateString, "MM/DD/YYYY h:mm:ss A");

    if (!date.isValid()) {
      throw new Error(`Invalid date string: ${dateString}`);
    }

    // Get the Unix timestamp (the number of seconds since the Unix Epoch)
    const seconds = date.unix();

    // Create a new Firebase Timestamp
    const timestamp = new Timestamp(seconds, 0);

    return timestamp;
  }

  const fixTime = async () => {
    const docRef = await getDocs(query(collection(db, "Data")));
    docRef.forEach(async (d) => {
      console.log(d.data().time);
      const newDate = toFirebaseTimestamp(d.data().time);
      await updateDoc(doc(db, "Data", d.id), {
        time: newDate,
      });
      console.log(d.data());
    });
  };

  function createSummary(arr) {
    if (arr.length === 0) {
      return "Sources";
    } else if (arr.length === 1) {
      return arr[0];
    } else {
      return `${arr[0]} and ${arr.length - 1} others`;
    }
  }

  const deletePost = async (link, id) => {
    await updateDoc(doc(db, "System", "DeletedPosts"), {
      deleted: arrayUnion(link),
    });
    console.log(link);
    await updateDoc(doc(db, "Data", id), {
      deleted: true,
    });
    const arr = trendingPosts;
    const arr3 = trendingPostsInt;
    const arr2 = posts;
    let filteredArr = arr.filter((item) => item.link !== link);
    let filteredArr2 = arr2.filter((item) => item.link !== link);
    let filteredArr3 = arr3.filter((item) => item.link !== link);
    setTrendingPosts(filteredArr);
    setTrendingPostsInt(filteredArr3);
    setPosts(filteredArr2);
    setDeletePrompt(false);
  };

  async function sendTelegramMessageNT(imageUrl, srr) {
    const botApi =
      "https://api.telegram.org/bot5994217325:AAFuI589JYY3HY1iqVqoXeRpahzq7wU85Hw/sendMessage";
    try {
      const response = await axios.post(botApi, {
        chat_id: `-1002079513335`,
        text: `Forwarded by <b>${cookies.get(
          "Login"
        )}</b>:\nsRR: ${srr}\n\n${imageUrl}`,
        parse_mode: "HTML",
      });
      const userId = cookies.get("Login");
      const docRef = await updateDoc(doc(db, "Usages", userId), {
        forwarded: increment(1),
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
    setForwardMode(false);
  }

  async function sendTelegramMessageCG(imageUrl, srr) {
    const botApi =
      "https://api.telegram.org/bot5994217325:AAFuI589JYY3HY1iqVqoXeRpahzq7wU85Hw/sendMessage";
    try {
      const response = await axios.post(botApi, {
        chat_id: `-1001667906653`,
        text: `Forwarded by <b>${cookies.get(
          "Login"
        )}</b>:\nsRR: ${srr}\n\n${imageUrl}`,
        parse_mode: "HTML",
      });
      console.log(response.data);
      const userId = cookies.get("Login");
      const docRef = await updateDoc(doc(db, "Usages", userId), {
        forwarded: increment(1),
      });
    } catch (error) {
      console.error(error);
    }
    setForwardMode(false);
  }

  const incrementDenUsage = async () => {
    const userId = cookies.get("Login");

    // Get the time the document was last opened from Firestore.
    const timeLastOpened = (await getDoc(doc(db, "Usages", userId))).data()
      .lastOpened;

    if (!timeLastOpened) {
      const docRef = await updateDoc(doc(db, "Usages", userId), {
        activityScore: 1,
      });
    }

    // Convert the Firestore timestamp to a moment object in Asia/Dhaka timezone.
    const momentLastOpened = moment(timeLastOpened?.toDate()).tz("Asia/Dhaka");

    // Get the current time in Asia/Dhaka timezone minus 5 minutes.
    const fiveMinutesAgo = moment().tz("Asia/Dhaka").subtract(60, "minutes");

    if (momentLastOpened.isBefore(fiveMinutesAgo)) {
      const docRef = await updateDoc(doc(db, "Usages", userId), {
        activityScore: increment(1),
      });
    }

    const docRef = await updateDoc(doc(db, "Usages", userId), {
      denOpens: increment(1),
      lastOpened: serverTimestamp(),
    });
    // const docRef = await getDocs(collection(db, "Users"));
    // docRef.forEach(async (d) => {
    //   console.log(d.data().username);
    //   const name = d.data().username;
    //   const docS = await setDoc(doc(db, "Usages", name), { denOpens: 0 });
    // });
  };

  useEffect(() => {
    // fetchPosts();
    fetchBotActivity();
    fetchRunrates();
    fetchTrendingPosts();
    // fixTime();
    incrementDenUsage();
  }, []);

  useEffect(() => {
    if (lastDoc == null) {
      setOver(false);
      fetchPosts();
      setUnapplied(false);
    }
  }, [lastDoc]);

  useEffect(() => {
    console.log(posts);
  }, [posts]);

  // useEffect(() => {
  //   setLastDoc(null);
  //   setPosts([]);
  // }, [selectedFilter, selectedSources]);

  useEffect(() => {
    console.log(selectedSources);
  }, [selectedSources]);

  const checkLoginStatus = async () => {
    const cook = cookies.get("Login");

    if (cook === "ibnul") {
      const isDenSession = cookies.get("isDenSession");

      if (!isDenSession) {
        cookies.remove("Login");
        navigate("/login");
      }
    }

    if (!cook) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  return (
    <>
      {deletePrompt && (
        <div className={classes.deletePrompt}>
          <label>Are you sure you want to delete?</label>
          <button
            onClick={() => deletePost(deletePrompt.link, deletePrompt.id)}
          >
            Yes
          </button>
          <button onClick={() => setDeletePrompt(false)}>No</button>
        </div>
      )}

      {forwardMode && (
        <div className={classes.deletePrompt}>
          <label>Select Dump</label>
          <button
            onClick={() =>
              sendTelegramMessageNT(forwardMode.link, forwardMode.srr)
            }
          >
            NT
          </button>
          <button
            onClick={() =>
              sendTelegramMessageCG(forwardMode.link, forwardMode.srr)
            }
          >
            CG
          </button>
          <button
            onClick={() => {
              sendTelegramMessageNT(forwardMode.link, forwardMode.srr);
              sendTelegramMessageCG(forwardMode.link, forwardMode.srr);
            }}
          >
            Both
          </button>
          <br></br>
          <button onClick={() => setForwardMode(false)}>Back</button>
        </div>
      )}

      <Header text="Curation" />
      <div className={`${classes.curationContainer} ${classes.subHeader}`}>
        <div className={classes.top}>
          <div
            onClick={() => {
              setToggleActivity(!toggleActivity);
            }}
          >
            {(status.time == "online" || status.timeBOT1 == "online") && (
              <OnlineIndicator />
            )}
            {status.time == "online" || status.timeBOT1 == "online"
              ? `${
                  status.time == "online" && status.timeBOT1 == "online"
                    ? `2`
                    : ``
                } Tyger${
                  status.time == "online" && status.timeBOT1 == "online"
                    ? `s`
                    : ``
                } Hunting `
              : `Tyger Sleeping`}
          </div>
          {toggleActivity && (
            <div>
              <div className={`${classes.subHeader}`}>Banani: {lastActive}</div>
              <div className={`${classes.subHeader}`}>
                Bot 1: {lastActiveBOT1}
              </div>
            </div>
          )}
          {/* <button className={classes.loadButton} onClick={calculateAvg}>
            Calculate
          </button> */}
          <br></br>
        </div>
        <div className={`${classes.subHeader} ${classes.loneHeader}`}>
          Trending Locally
        </div>
        <br></br>

        {trendingPosts.length > 0 && (
          <Carousel
            responsive={responsive}
            showDots={true}
            dotListClass="dot-list"
          >
            {avgRates &&
              trendingPosts &&
              trendingPosts.map((p) => {
                return (
                  <div style={{ paddingBottom: "2rem" }} key={p.id}>
                    <PostCard
                      Headline={
                        p.caption && p.caption != "" ? p.caption : p.capp
                      }
                      Id={p.id}
                      Image={p.img}
                      Vertical={p.vertical}
                      Link={p.link}
                      RunRate={p.runrate}
                      SuperRunRate={p.srr ? p.srr : ``}
                      Time={timeAgoFirebase(p.time)}
                      Chk={timeAgoFirebase(p.lastUpdated)}
                      setDeletePrompt={setDeletePrompt}
                      setForwardMode={setForwardMode}
                    />
                  </div>
                );
              })}
          </Carousel>
        )}
        <br></br>
        <div className={`${classes.subHeader} ${classes.loneHeader}`}>
          Trending Internationally
        </div>
        <br></br>

        {trendingPostsInt.length > 0 && (
          <Carousel
            responsive={responsive}
            showDots={true}
            dotListClass="dot-list"
          >
            {avgRates &&
              trendingPostsInt &&
              trendingPostsInt.map((p) => {
                return (
                  <div style={{ paddingBottom: "2rem" }} key={p.id}>
                    <PostCard
                      Headline={
                        p.caption && p.caption != "" ? p.caption : p.capp
                      }
                      Id={p.id}
                      Image={p.img}
                      Vertical={p.vertical}
                      Link={p.link}
                      RunRate={p.runrate}
                      SuperRunRate={p.srr ? p.srr : ``}
                      Time={timeAgoFirebase(p.time)}
                      Chk={timeAgoFirebase(p.lastUpdated)}
                      setDeletePrompt={setDeletePrompt}
                      setForwardMode={setForwardMode}
                    />
                  </div>
                );
              })}
          </Carousel>
        )}
        <br></br>

        <div className={`${classes.subHeader}  ${classes.loneHeader}`}>
          Discovery
        </div>
        <br></br>
        <div className={classes.middle}>
          <div>
            {
              <Button
                text={createSummary(selectedSources)}
                active={sourcesToggled}
                handleClick={() => {
                  if (sourcesToggled == true) {
                    console.log("x");
                    setLastDoc(null);
                    setPosts([]);
                  }
                  setSourcesToggled(!sourcesToggled);
                  setFilterToggled(false);
                }}
              />
            }
            {
              <Button
                text={`Sorted by: ${selectedFilter}`}
                active={filterToggled}
                handleClick={() => {
                  if (filterToggled == true) {
                    console.log("x");
                    setLastDoc(null);
                    setPosts([]);
                  }
                  setSourcesToggled(false);
                  setFilterToggled(!filterToggled);
                }}
              />
            }
          </div>
          {(filterToggled || sourcesToggled) && <br></br>}
          <div>
            {filterToggled &&
              filters.map((s) => {
                return (
                  <Button
                    text={s}
                    id={s}
                    active={s == selectedFilter}
                    handleClick={(e) => {
                      setSelectedFilter(s);
                      setUnapplied(true);
                    }}
                  />
                );
              })}
            {sourcesToggled &&
              sources.map((s) => {
                return (
                  <Button
                    text={s}
                    id={s}
                    active={selectedSources.includes(s)}
                    handleClick={(e) => {
                      setUnapplied(true);
                      let obj = {};
                      if (selectedSources.includes(s)) {
                        setSelectedSources((prev) => {
                          return prev.filter((item) => item !== s);
                        });
                      } else {
                        setSelectedSources((prev) => {
                          return [...prev, s];
                        });
                      }
                    }}
                  />
                );
              })}
          </div>
          {(filterToggled || sourcesToggled) && <br></br>}
          {unApplied && (
            <label className={classes.unApplied}>
              Apply for changes to take effect
            </label>
          )}
          {unApplied && <br></br>}
          <div className={classes.applyBtn}>
            {(filterToggled || sourcesToggled) && (
              <Button
                text="Apply Filters"
                handleClick={() => {
                  setLastDoc(null);
                  setPosts([]);
                  setFilterToggled(false);
                  setSourcesToggled(false);
                }}
              />
            )}
          </div>
        </div>

        <br></br>
        <div className={classes.allPosts}>
          {avgRates &&
            posts.map((p) => {
              return (
                <PostCard
                  Headline={p.caption}
                  Image={p.img}
                  Id={p.id}
                  Vertical={p.vertical}
                  Link={p.link}
                  RunRate={p.runrate}
                  SuperRunRate={p.srr ? p.srr : ``}
                  Time={timeAgoFirebase(p.time)}
                  Chk={timeAgoFirebase(p.lastUpdated)}
                  setDeletePrompt={setDeletePrompt}
                  setForwardMode={setForwardMode}
                />
              );
            })}
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className={classes.buttonContainer}>
            {!over && (
              <button className={classes.loadButton} onClick={fetchPosts}>
                Load More
              </button>
            )}
          </div>
        )}
        <br></br>
        <br></br>
        <br></br>
      </div>
    </>
  );
};

export default Curation;
