import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  return (
    <div>
      <button
        className={`btn-nav ${isOpen ? "animated" : ""}`}
        onClick={toggleNav}
      >
        <span className="icon-bar top"></span>
        <span className="icon-bar middle"></span>
        <span className="icon-bar bottom"></span>
      </button>
      <div className={`nav-content ${isOpen ? "showNav" : "hideNav"}`}>
        <ul className="nav-list">
          <li className="nav-item">
            <Link onClick={() => toggleNav()} to="/" className="item-anchor">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link onClick={() => toggleNav()} to="/" className="item-anchor">
              Tygers
            </Link>
          </li>
          <li className="nav-item">
            <Link
              onClick={() => toggleNav()}
              to="/sources"
              className="item-anchor"
            >
              Sources
            </Link>
          </li>
        </ul>
        <div className="line-betwen"></div>
        <SocialLinks />
      </div>
    </div>
  );
}

function SocialLinks() {
  return (
    <div className="social-links">
      <ul className="social-list">
        <li>
          <a
            href="https://www.facebook.com/mitar.djakovic.7"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-facebook"></i>
          </a>
        </li>
        <li>
          <a
            href="https://rs.linkedin.com/in/mitar-djakovic-77490ab5"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-linkedin"></i>
          </a>
        </li>
        <li>
          <a
            href="mailto:mitar-djakovic2401993@hotmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-envelope-o"></i>
          </a>
        </li>
        <li>
          <a href="https://github.com/roker93" target="_blank" rel="noreferrer">
            <i className="fa fa-github"></i>
          </a>
        </li>
        <li>
          <a
            href="https://codepen.io/roker2401993"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-codepen"></i>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
