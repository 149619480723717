import { useState } from "react";
import Header from "../components/Header";
import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { useEffect } from "react";
import SourceCard from "../components/SourceCard";
import classes from "../styles/Sources.module.css";

const Sources = () => {
  const [sources, setSources] = useState({});
  const [sourceCount, setSourceCount] = useState({});
  const [lastUpdated, setLastUpdated] = useState({});
  const [num, setNum] = useState({});

  const fetchPostCount = async (vertical) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const docRef = await getCountFromServer(
      query(
        collection(db, "Data"),
        where("date", "==", now),
        where("vertical", "==", vertical)
      )
    );
    let obj = {};
    obj[vertical] = docRef.data().count;
    setSourceCount((prev) => {
      return { ...prev, ...obj };
    });
  };

  const fetchSources = async () => {
    const docRef = await getDoc(doc(db, "System", "RunrateData"));
    setSources(docRef.data());
  };

  const fetchLastUpdated = async () => {
    const docRef = await getDoc(doc(db, "System", "lastActive"));
    setLastUpdated(docRef.data());
  };

  const fetchNum = async () => {
    const docRef = await getDoc(doc(db, "System", "TotalData"));
    setNum(docRef.data());
  };

  useEffect(() => {
    fetchSources();
    fetchNum();
    fetchLastUpdated();
  }, []);

  useEffect(() => {
    if (sources != {}) {
      Object.keys(sources).map((s) => {
        fetchPostCount(s);
      });
    }
  }, [sources]);
  return (
    <>
      <Header text="Sources" />
      <br></br>
      <div className={classes.sourcesContainer}>
        {Object.keys(sources).map((s) => {
          return (
            <SourceCard
              Source={s}
              aRR={sources[s]}
              count={sourceCount[s]}
              updated={lastUpdated[s]}
              numS={num && num[s] && num[s].length}
            />
          );
        })}
      </div>
    </>
  );
};

export default Sources;
